
.linked-content {
   a {
     all: unset;
     cursor: pointer;
   }
  * {
    text-decoration: none;
    &:visited, &:hover, &:active {
      color: inherit;
    }
  }
  margin-bottom : 85px;

  .title {
    font-size: 38px;
    line-height: 1em;
    font-weight: 300;
  }

  .subtitle {
    color: #adadad;
    font-size: 30px;
    margin-bottom: 5px;
  }
}
