@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
* {
  margin : 0;
  padding : 0;
  font-family: 'Heebo', sans-serif;
}
html, body, #root {
  height:100%;
}
.w-100 {
  width: 100%;
}
.content {
  width: 76%;
  margin: auto;
  @media screen and (max-width: 660px) {
    width: 85%;
  }
}
