
.more {
  background-color : #303030;
  text-align: center;
  padding : 77px 0;

  p {
    color : #9e9e9e;
    font-size: 23px;
    font-weight: 300;
    margin: 0 auto 20px;
    width : 85%;
    @media screen and (max-width: 660px) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }
  .button {
    color : #FFF;
    text-decoration: none;
    border: solid 1px #FFF;
    padding : .3em 1em;
    letter-spacing: 1px;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      background-color: #FFF;
      color : #000;
      transition: 300ms;
    }
    &:active {
      transform: scale(0.9);
    }
  }
}