.padding {
  padding : 140px;
  @media screen and (max-width: 660px) {
    padding : 100px;
  }
}
.artwork-presentation {
  background: linear-gradient(180deg, #f1f1f1 0%, #fff 100%);
  padding-top: 110px;
  margin-bottom: 20px;

  .title {
    font-family: 'Roboto Condensed', sans-serif;
    color: #333333;
    font-size: 16px;
    margin-top: 30px;
  }

  .value {
    color: #666666;
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
  }
  h1 {
    font-size: 80px;
    font-weight: 300;
    line-height: 1em;
    @media screen and (max-width: 660px) {
        font-size: 42px;
    }
  }

  h2 {
    font-size: 48px;
    @media screen and (max-width: 660px) {
      font-size: 32px;
    }
    color: #adadad;
    font-weight: 500;
    padding-bottom: 40px;
  }
}

.bloc {
  padding-top: 110px;
}

p {
  font-weight: 300;
  font-size: 18px;
  line-height: 36px;
}

.artwork-picture {
  width: 100%;
}
.disclaimer-container {
  margin : 160px 0 40px;
}
.zoom-container {
  margin : 0 auto 120px;
  width: 76%;
  @media screen and (max-width: 660px) {
    width: 100%;
  }
  img {
    width: 100%;
  }
}
.author {
  font-size: 18px;
  font-weight: 500;
}

.mention {
  line-height: 20px;
  font-size: 11px;
}

