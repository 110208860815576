.newsletter-container {
  padding : 150px 10px;
  .content {
    padding : 40px;
    background-color: rgba(255,255,255,0.6);
  }
  p {
    font-size: 50px;
    @media screen and (max-width: 660px) {
      font-size: 28px;
      line-height: 40px;
    }
    font-weight: 300;
    color : #666;
    line-height: 55px;
  }
  input {
    border: none;
    padding : 16px;
    outline: none;
    font-weight: 300;
    color: #7a7a7a;
    font-size: 16px;
    width: 100%;
    max-width: 300px;
    margin: 10px;
    &:focus {
      background-color: #f6f6f6;
      color: #000 !important;
    }
    @media screen and (max-width: 967px) {
      max-width: 100%;
      margin : 10px 0;
    }
  }
  button {
    color : #FFF;
    background-color: #666666;
    text-decoration: none;
    border: solid 1px #727272;
    padding : .3em 1em;
    letter-spacing: 1px;
    font-size: 20px;

    margin: 10px;
    cursor: pointer;
    &:hover {
      background-color: #575757;
      transition: 350ms;
    }
    &:active {
      transform: scale(0.95);
    }
  }
}