.parallax-background {
  &.top {
    @media screen and (max-width: 660px) {
      background-size: contain;
      background-position: inherit;
    }
  }
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
}