

.client-logo-container {
  padding: 77px 0;
  text-align: center;
  img {
    width: 400px;
    @media screen and (max-width: 660px) {
      width: 300px;
    }
  }
}

.scroll-container {
  margin : 30px 0 60px;
  text-align: center;
  img {
    width: 45px;
  }
}

.stripes-container {
  img {
    width: 100%;
  }
}

.presentation {
  background: linear-gradient(to bottom, #f2f2f2, #FFF);
  padding : 90px 0;
  h1, h2 {
    text-align: center;

  }
  h1 {
    color : #adadad;
    font-weight: 300;
    font-size: 80px;
    text-overflow: ellipsis;
    @media screen and (max-width: 660px) {
      font-size: 42px;
    }
  }
  h2 {
    color : #333333;
    font-weight: 500;
    font-size: 48px;
    @media screen and (max-width: 660px) {
      font-size: 32px;
    }
    line-height: 1em;
  }
  p {
    font-weight: 300;
    line-height: 36px;
    font-size: 18px;
  }
  .introduction {
    margin: 40px 0;
  }
  img {
    width: 100%;
  }
  .author {
    font-weight: 500;
    font-size: 18px;
  }
}

.rolling-container {
  width: 76%;
  margin : auto;
  @media screen and (max-width: 660px) {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.about {
  background: linear-gradient(to bottom, #f2f2f2, #FFF);
  padding: 90px 0;
  margin-top: 120px;
  h2 {
    color : #333333;
    font-weight: 500;
    font-size: 48px;
  }
  p {
    font-weight: 300;
    line-height: 36px;
    font-size: 18px;
  }
  img {
    width: 100%;
  }
  .author {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
  }
}
