.homepage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background : #CECECE;
  img {
    width: 100%;
    max-width: 600px;
  }
}