@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
.login-container{display:flex;justify-content:center;align-items:center;min-height:100%}

.homepage{display:flex;align-items:center;justify-content:center;height:100%;background:#CECECE}.homepage img{width:100%;max-width:600px}

.window-logo-container{text-align:center;margin:14px 0}.window-logo-container img{width:100px;cursor:pointer;vertical-align:middle}

.parallax-background{background-size:cover;background-position:bottom;background-attachment:fixed;background-repeat:no-repeat}@media screen and (max-width: 660px){.parallax-background.top{background-size:contain;background-position:inherit}}

.padding{padding:140px}@media screen and (max-width: 660px){.padding{padding:100px}}.artwork-presentation{background:linear-gradient(180deg, #f1f1f1 0%, #fff 100%);padding-top:110px;margin-bottom:20px}.artwork-presentation .title{font-family:'Roboto Condensed', sans-serif;color:#333333;font-size:16px;margin-top:30px}.artwork-presentation .value{color:#666666;font-weight:700;font-size:20px;margin-top:15px}.artwork-presentation h1{font-size:80px;font-weight:300;line-height:1em}@media screen and (max-width: 660px){.artwork-presentation h1{font-size:42px}}.artwork-presentation h2{font-size:48px;color:#adadad;font-weight:500;padding-bottom:40px}@media screen and (max-width: 660px){.artwork-presentation h2{font-size:32px}}.bloc{padding-top:110px}p{font-weight:300;font-size:18px;line-height:36px}.artwork-picture{width:100%}.disclaimer-container{margin:160px 0 40px}.zoom-container{margin:0 auto 120px;width:76%}@media screen and (max-width: 660px){.zoom-container{width:100%}}.zoom-container img{width:100%}.author{font-size:18px;font-weight:500}.mention{line-height:20px;font-size:11px}

.disclaimer{color:#808080;font-weight:700;font-size:14px;text-align:center;margin:50px}

.more{background-color:#303030;text-align:center;padding:77px 0}.more p{color:#9e9e9e;font-size:23px;font-weight:300;margin:0 auto 20px;width:85%}@media screen and (max-width: 660px){.more p{font-size:20px;line-height:30px;margin-bottom:24px}}.more .button{color:#FFF;text-decoration:none;border:solid 1px #FFF;padding:.3em 1em;letter-spacing:1px;font-size:20px;cursor:pointer}.more .button:hover{background-color:#FFF;color:#000;transition:300ms}.more .button:active{transform:scale(0.9)}

.newsletter-container{padding:150px 10px}.newsletter-container .content{padding:40px;background-color:rgba(255,255,255,0.6)}.newsletter-container p{font-size:50px;font-weight:300;color:#666;line-height:55px}@media screen and (max-width: 660px){.newsletter-container p{font-size:28px;line-height:40px}}.newsletter-container input{border:none;padding:16px;outline:none;font-weight:300;color:#7a7a7a;font-size:16px;width:100%;max-width:300px;margin:10px}.newsletter-container input:focus{background-color:#f6f6f6;color:#000 !important}@media screen and (max-width: 967px){.newsletter-container input{max-width:100%;margin:10px 0}}.newsletter-container button{color:#FFF;background-color:#666666;text-decoration:none;border:solid 1px #727272;padding:.3em 1em;letter-spacing:1px;font-size:20px;margin:10px;cursor:pointer}.newsletter-container button:hover{background-color:#575757;transition:350ms}.newsletter-container button:active{transform:scale(0.95)}

footer{background-color:#303030;text-align:center;padding:30px 0}footer p,footer a{color:#9e9e9e;font-size:14px;font-weight:300}

.agent-container{text-align:center;margin:100px 0}.agent-container h3{margin-bottom:20px}.agent-container a{color:#000}.agent-container p{margin-top:6px;line-height:1.5em}

.linked-content{margin-bottom:85px}.linked-content a{all:unset;cursor:pointer}.linked-content *{text-decoration:none}.linked-content *:visited,.linked-content *:hover,.linked-content *:active{color:inherit}.linked-content .title{font-size:38px;line-height:1em;font-weight:300}.linked-content .subtitle{color:#adadad;font-size:30px;margin-bottom:5px}

.client-logo-container{padding:77px 0;text-align:center}.client-logo-container img{width:400px}@media screen and (max-width: 660px){.client-logo-container img{width:300px}}.scroll-container{margin:30px 0 60px;text-align:center}.scroll-container img{width:45px}.stripes-container img{width:100%}.presentation{background:linear-gradient(to bottom, #f2f2f2, #fff);padding:90px 0}.presentation h1,.presentation h2{text-align:center}.presentation h1{color:#adadad;font-weight:300;font-size:80px;text-overflow:ellipsis}@media screen and (max-width: 660px){.presentation h1{font-size:42px}}.presentation h2{color:#333333;font-weight:500;font-size:48px;line-height:1em}@media screen and (max-width: 660px){.presentation h2{font-size:32px}}.presentation p{font-weight:300;line-height:36px;font-size:18px}.presentation .introduction{margin:40px 0}.presentation img{width:100%}.presentation .author{font-weight:500;font-size:18px}.rolling-container{width:76%;margin:auto}@media screen and (max-width: 660px){.rolling-container{width:100%}}.rolling-container img{width:100%}.about{background:linear-gradient(to bottom, #f2f2f2, #fff);padding:90px 0;margin-top:120px}.about h2{color:#333333;font-weight:500;font-size:48px}.about p{font-weight:300;line-height:36px;font-size:18px}.about img{width:100%}.about .author{margin-top:20px;font-weight:500;font-size:18px}

*{margin:0;padding:0;font-family:'Heebo', sans-serif}html,body,#root{height:100%}.w-100{width:100%}.content{width:76%;margin:auto}@media screen and (max-width: 660px){.content{width:85%}}

